import * as React from 'react'
import { useField, useFormikContext, FieldConfig } from 'formik'
import { BaseSchema } from 'yup'
import { TextInput, TextInputProps } from '@toasttab/buffet-pui-text-input'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import {
  transformSchemaValidation,
  validate,
  transformFormikErrors,
  BaseRule,
  RulesMap
} from './utils'
import { FieldRequirements } from './FieldRequirements'
import { FormValuesWithName, TypedName } from '../commonTypes'

type CommonProps = {
  /**
   * Rules that are passed to the `FieldRequirements` component.
   * The name of each rule must match the message in the yup schema. If your
   * schema contains a `required` test, it has to have the `required` message.
   * You can then decide to have a rule for it or not. If not, `RequiredField`
   * will implicitly invalid all the other rule.
   */
  rules: RulesMap<BaseRule>
  /** Can be used during development to log the rules and their state. */
  debug?: boolean
}

type UseRulesProps = CommonProps & {
  error?: string
  touched: boolean
}

export type RequiredFieldProps<FormValues extends FormValuesWithName = string> =
  RemoveFields<FieldConfig<string>, 'name'> &
    RemoveFields<
      React.InputHTMLAttributes<HTMLInputElement>,
      'size' | 'prefix' | 'name'
    > &
    RemoveFields<TextInputProps, 'name'> &
    CommonProps &
    TypedName<FormValues> & {
      /** `yup` schema used to validate the field. If you want this field to be required, you can use the `.require('required')` method */
      schema: BaseSchema<string | undefined>
      requirementsLabel?: string
      requirementsId?: string
    }

const useRules = ({ rules, error, touched, debug = false }: UseRulesProps) => {
  const effectiveRules = React.useMemo(() => {
    const errors = transformFormikErrors(error)
    return transformSchemaValidation(rules, touched, errors)
  }, [rules, error, touched])

  debug &&
    ['development', 'test'].includes(process.env.NODE_ENV ?? '') &&
    console.log({ effectiveRules, error, touched })

  return effectiveRules
}

/**
 * Alternative `TextInputField` which uses `FieldRequirements` instead of helper
 * text.
 */
export const RequiredField = <FormValues extends FormValuesWithName = string>({
  schema,
  rules,
  debug,
  requirementsLabel,
  requirementsId,
  ...props
}: RequiredFieldProps<FormValues>) => {
  const { testId, onChange, ...restProps } = props
  const { values = {} } = useFormikContext()
  const [field, meta, helpers] = useField({
    ...restProps,
    validate: validate(schema, values),
    size: undefined,
    prefix: undefined
  })

  const effectiveRules = useRules({ ...meta, rules, debug })
  const invalid = meta.touched && !!meta.error

  return (
    <div className='grid grid-cols-1 gap-2'>
      <TextInput
        invalid={invalid}
        {...field}
        {...props}
        onChange={(e) => {
          helpers.setTouched(true)
          helpers.setValue(e.target.value)
          onChange?.(e)
        }}
      />
      <FieldRequirements
        rules={effectiveRules}
        testId={`${testId}-requirements`}
        label={requirementsLabel}
        id={requirementsId}
      />
    </div>
  )
}
