import * as React from 'react'
import { RegistrationErrorView } from './RegistrationErrorView'
import { NoIdErrorView } from './NoIdErrorView'
import { ExpiredLinkErrorView } from './ExpiredLinkErrorView'
import { AccountAlreadyExistsErrorView } from './AccountAlreadyExistsErrorView'
import { RegistrationErrorType } from './RegistrationErrorType'

export const RegistrationError = (props: {
  registrationErrorType: RegistrationErrorType
}) => {
  const { registrationErrorType } = props

  switch (registrationErrorType) {
    case RegistrationErrorType.NoId:
      return <NoIdErrorView />
    case RegistrationErrorType.ExpiredLink:
      return <ExpiredLinkErrorView />
    case RegistrationErrorType.AccountAlreadyExists:
      return <AccountAlreadyExistsErrorView />
    case RegistrationErrorType.Generic:
    default:
      return <RegistrationErrorView />
  }
}
