import { userMigrationReducer } from './reducer'
import React, { useReducer } from 'react'
import {
  EmailSource,
  InitialState,
  Migrated,
  NewEmail,
  PayrollEmail,
  ToastEmail
} from './state'
import { UserMigrationContextProvider } from './UserMigrationContextProvider'
import { client, queryClient } from '@local/api/client'
import { ApolloProvider } from '@apollo/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { Routes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { TranslationProvider } from '@local/translations'
import { userRegistrationReducer } from './reducer'
import { UserRegistrationContextProvider } from './UserRegistrationContextProvider'

export function App() {
  const [state, dispatch] = useReducer(userMigrationReducer, {
    type: 'initial-state'
  } as InitialState)

  const [registrationState, registrationDispatch] = useReducer(
    userRegistrationReducer,
    {
      type: 'initial-state'
    } as InitialState
  )

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <UserMigrationContextProvider state={state} dispatch={dispatch}>
          <UserRegistrationContextProvider
            state={registrationState}
            dispatch={registrationDispatch}
          >
            <BrowserRouter>
              <SnackBarProvider>
                <TranslationProvider>
                  <Routes />
                </TranslationProvider>
              </SnackBarProvider>
            </BrowserRouter>
          </UserRegistrationContextProvider>
        </UserMigrationContextProvider>
      </QueryClientProvider>
    </ApolloProvider>
  )
}
