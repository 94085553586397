import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import {
  namespacedTFunction,
  createLoadStrings
} from '@toasttab/buffet-pui-translation-utilities'
import enUS from './locales/en-US.json'
import esUS from './locales/es-US.json'

export const name = '@toasttab/buffet-pui-alerts'

const defaultStringsCMF = enUS

export type DefaultStrings = Record<keyof typeof defaultStringsCMF, string>

const defaultStringKeys = Object.keys(defaultStringsCMF) as Array<
  keyof typeof defaultStringsCMF
>

export const defaultStrings = defaultStringKeys.reduce((acc, key) => {
  acc[key] = defaultStringsCMF[key].message
  return acc
}, {} as DefaultStrings)

const locales: Partial<Record<Locale, Partial<DefaultStrings>>> = {
  'es-US': esUS
}

/**
 * Loads the strings for a given locale.
 * @param locale - The locale for which to load the strings.
 */
export const loadStrings = createLoadStrings(name, defaultStrings, locales)

export const t = namespacedTFunction<DefaultStrings>(name)
