import { routeFactory } from '@local/react-router-extensions/route-factory'

const userMigrationBase = `/user/migration`

const {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
} = routeFactory({
  index: `${userMigrationBase}`,
  confirmEmail: `${userMigrationBase}/confirmations/:email/:confirmationId`,
  register: `/login/:companyCode/:registrationId/register`
})

export {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
}
