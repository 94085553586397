import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const WarningOutlineDisplayIcon = React.forwardRef(
  function WarningOutlineDisplayIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={64}
              height={64}
              viewBox='0 0 64 64'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'xl'].className
              )}
            >
              <path
                d='M27.257 14.213c2.2-3.5 7.3-3.5 9.5 0l9.5 15.3 8.9 14.4c2.3 3.7-.4 8.5-4.7 8.5h-36.9c-4.4 0-7-4.8-4.7-8.5l8.9-14.4 9.5-15.3z'
                stroke='#5069C3'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M31.957 23.512v10.9'
                stroke='#FF4C00'
                strokeWidth={sizes[props.size || 'xl'].displayStrokeWidth}
                strokeLinecap='round'
              />
              <path
                d='M31.957 45.013a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z'
                fill='#FF4C00'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
