import * as React from 'react'
import { TextInputField, TextInputFieldProps } from '../TextInputField'
import { RequiredField, RequiredFieldProps } from '../RequiredField'
import {
  deprecatedRules,
  deprecatedSchema,
  updatedRules,
  updatedSchema
} from './utils'
import { usePasswordVisibility } from './usePasswordVisibility'
import { FormValuesWithName } from '../commonTypes'
import { t, loadStrings } from '../defaultStrings'

export type PasswordFieldWithRequirementsProps<
  FormValues extends FormValuesWithName = string
> = RequiredFieldProps<FormValues> & { useNewRequirements?: boolean }

export type PasswordFieldProps<FormValues extends FormValuesWithName = string> =
  TextInputFieldProps<FormValues>

const commonProps: PasswordFieldProps = {
  testId: 'password-field',
  name: 'password',
  label: 'password',
  placeholder: 'password',
  maxLength: 512,
  suffixVariant: 'iconButton'
}

/**
 * Field for password with customizable requirements, useful when
 * creating/updating the value.
 */
export const PasswordFieldWithRequirements = <
  FormValues extends FormValuesWithName = string
>(
  props: Partial<PasswordFieldWithRequirementsProps<FormValues>>
) => {
  const { type, Suffix } = usePasswordVisibility()
  let propsToPass = { ...props }
  delete propsToPass.useNewRequirements
  loadStrings()

  return (
    <RequiredField<FormValues>
      {...(commonProps as PasswordFieldProps<FormValues>)}
      label={t('password')}
      placeholder={t('password')}
      type={type}
      suffix={<Suffix />}
      rules={props.useNewRequirements ? updatedRules() : deprecatedRules()}
      schema={props.useNewRequirements ? updatedSchema : deprecatedSchema}
      requirementsLabel={t('password-must')}
      {...propsToPass}
    />
  )
}

/**
 * Simple field for password without requirements, useful for confirming or
 * typing old password, or during the login process.
 */
export const PasswordField = <FormValues extends FormValuesWithName = string>(
  props: Partial<PasswordFieldProps<FormValues>>
) => {
  const { type, Suffix } = usePasswordVisibility()
  loadStrings()

  return (
    <TextInputField<FormValues>
      {...(commonProps as PasswordFieldProps<FormValues>)}
      label={t('password')}
      placeholder={t('password')}
      type={type}
      suffix={<Suffix />}
      {...props}
    />
  )
}
