import * as React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { WarningOutlineDisplayIcon } from '@toasttab/buffet-pui-icons'

export interface ErrorModalProps {
  testId?: string
  isOpen: boolean
  heading: string
  onOkay: () => void
  okayButtonText?: string
}

export const ErrorModal: React.FC<React.PropsWithChildren<ErrorModalProps>> = ({
  testId = 'error-modal',
  isOpen = false,
  heading = '',
  onOkay,
  okayButtonText = 'Okay',
  children
}) => {
  return (
    <Modal isOpen={isOpen} size='lg' position='pin-center' testId={testId}>
      <ModalHeader>
        <div className='flex items-center'>
          <WarningOutlineDisplayIcon size='lg' accessibility='decorative' />
          <h2 className='ml-4 type-headline-5'>{heading}</h2>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter stackOnMobile>
        <Button onClick={onOkay} testId={`${testId}-okay`}>
          {okayButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
