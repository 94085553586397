import { useEffect } from 'react'
import { useParamsFromRoute } from '../routes'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import React from 'react'
import { Register } from '@local/api/documents/userRegistrationGraphQLDocuments'
import { useUserRegistration } from '../UserRegistrationContextProvider'
import { ToastUserExists } from '../userRegistrationState'

export const RegisterUser = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserRegistration<ToastUserExists>()
  const { companyCode, registrationId } = useParamsFromRoute('register')
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()

  useEffect(() => {
    if (registrationId && companyCode) {
      const registerMutation = async () => {
        const result = await client.mutate({
          mutation: Register,
          variables: {
            registrationId: registrationId,
            companyCode: companyCode,
            password: null
          },
          fetchPolicy: 'network-only'
        })

        const register = result.data?.payrollUser?.register

        switch (register?.__typename) {
          case 'UserRegistered':
            dispatch({
              type: 'registered',
              email: state.email,
              registrationId: registrationId
            })
            break
          case 'UserRegistrationUserAlreadyRegistered':
            dispatch({
              type: 'account-exists-registration-error',
              email: state.email,
              registrationId: registrationId
            })
            break
          case 'UserRegistrationExpired':
            dispatch({
              type: 'expired-link-registration-error',
              registrationId: registrationId
            })
            break
          case 'UserRegistrationIdNotFound':
          case 'UserRegistrationPayrollUserNotFound':
            dispatch({
              type: 'no-id-registration-error',
              registrationId: registrationId
            })
            break
          case 'UserRegistrationPasswordNotProvided':
          case 'UserRegistrationUserNotActive':
          case 'UserRegistrationEmailNotVerified':
          case 'UserRegistrationMutationFailed':
          default:
            dispatch({
              type: 'registration-error',
              registrationId: registrationId
            })
            showErrorSnackBar(t('anErrorOccurred'))
            break
        }
      }
      registerMutation()
    }
  }, [companyCode, registrationId, client, dispatch, showErrorSnackBar, t])

  return <></>
}
