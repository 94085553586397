export const translations = {
  logout: 'Log out',
  skipForNow: 'Skip for now',
  continue: 'Continue',
  resendEmail: 'Resend email',
  editEmail: 'Edit email',
  enterYourEmail: 'Enter your email',
  toastLogin: 'Toast login',
  loginEmail: 'Login email',
  newEmail: 'Email',
  anErrorOccurred: 'An error occurred',
  anErrorOccurredRetrying: 'An error occurred. Retrying...',
  oopsWeHaveSomeCleaningUpToDo: 'Oops we have some cleaning up to do',
  oopsSomethingWentWrong: 'Oops, something went wrong',
  uhohSomethingWentWrong: 'Uh oh, something went wrong',
  uhohExpiredLink: 'Uh oh, your link expired',
  uhohErrorCreatingAccount: 'Uh oh, we couldn’t create your account',
  questionAndAnswer: 'Question and answer',
  emailLabel: 'Email',
  problemSendingEmail:
    'There was a problem sending the verification email. Please try again.',
  tryLinkAgain:
    'Please wait a few minutes and try the link in your email again. If the issue continues, contact your manager.',
  errorCreatingAccount:
    'We couldn’t create your account. Please contact your manager.',
  accountAlreadyExists: 'Looks like you already have an account',
  tryLoggingInAgain:
    'Since you already have an account with us, try logging in instead',
  pleaseContactYourManager: 'Please contact your manager.',
  chooseAnotherEmail: 'Choose another email',
  emailInUse:
    'The email you have chosen is already in use. Please choose another.',
  emailInUseInCustomer:
    'Someone at your company is using the email you selected. Please choose another or talk to your manager if that is your email address.',
  validEmailRequired: 'A valid email is required',
  start: {
    heading:
      "This'll just take a sec! Use one login for Toast Payroll, Mobile Apps, & Toast Backend.",
    body: 'This will allow you to easily navigate between Toast products.',
    listHeading:
      'Connect your Payroll account to Toast so you will be able to:',
    listItem1:
      'Use one login to easily navigate between all your Toast products',
    listItem2: 'Manage your information in one place',
    listItem3: 'Know that your personal information is more secure',
    question1: 'Create a login using your email or phone',
    question2: 'Verify new login',
    question3: 'Create a new password',
    whatYouWillNeedToDo: "What you'll need to do"
  },
  newAccount: {
    heading:
      'Select an email to connect your Toast accounts; this will be your new login for all of Toast',
    body: 'Choose an active email to login to all your Toast products. You will need to verify the email you choose. ',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  toastAccount: {
    heading:
      'Choose an email to log in to all of your Toast accounts for this location',
    body: 'We’ve found a Toast Web account associated with your email. Connect your Payroll and Toast accounts to easily navigate through Toast.',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  payrollAccount: {
    heading:
      'Choose an email to log in to all of your Toast accounts for this location',
    body: 'Connect your Payroll account with your Toast Web account to easily navigate between all your Toast accounts.',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  email: {
    confirm: {
      heading: 'Confirming your email',
      body: "We're confirming your email and setting up your account",
      emailNotVerified: 'Email not verified',
      errorCheckYourEmail:
        'Check your email and use the most recent verification link.'
    },
    confirmed: {
      emailVerified: 'Email verified!',
      isReadyToBeUsed:
        'is ready to be used to login to all of your Toast accounts!'
    },
    verified: {
      emailVerified: 'Email verified! You can close this tab.',
      isVerified:
        'is verified to be used for your Toast account. You can close this window and continue in the new Toast tab to create your new password.'
    },
    verify: {
      checkYourEmail: 'Check your email',
      emailResentTo: 'Email resent to',
      weHaveSentAnEmailTo: 'We have sent an email to',
      toVerifyYourEmailAndConnectYourAccounts:
        'to verify your email to connect your accounts.'
    }
  },
  password: {
    typePassword: 'Type password',
    confirmYourPassword: 'Confirm your password',
    createANewPassword: 'Create a new password',
    passwordIsRequired: 'Password is required',
    createANewPasswordToUse:
      'Create a new password to use to log into your Toast and Payroll account.'
  },
  complete: {
    youAreAllSetUpShort: 'You’re all set up!',
    youAreAllSetUp:
      'You’re all set up to use {{email}} for all your Toast logins.',
    isReadyToBeUsedToLoginShort:
      '<bold>{{email}}</bold> is ready to be used to login to all your Toast accounts!',
    isReadyToBeUsedToLogin:
      'You successfully changed your login to <bold>{{email}}</bold> to use for all your Toast products including Toast Payroll at'
  }
}
