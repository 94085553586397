import * as React from 'react'
import { PropsWithChildren } from 'react'
import { UserRegistrationState } from './userRegistrationState'
import { UserRegistrationDispatch } from './reducer'

export const UserRegistrationStateContext = React.createContext<
  UserRegistrationState | undefined
>(undefined)

export const UserRegistrationDispatchContext = React.createContext<
  UserRegistrationDispatch | undefined
>(undefined)

export function UserRegistrationContextProvider({
  children,
  state,
  dispatch
}: {
  state: UserRegistrationState
  dispatch: UserRegistrationDispatch
} & PropsWithChildren) {
  return (
    <UserRegistrationStateContext.Provider value={state}>
      <UserRegistrationDispatchContext.Provider value={dispatch}>
        {children}
      </UserRegistrationDispatchContext.Provider>
    </UserRegistrationStateContext.Provider>
  )
}

export function useUserRegistration<T extends UserRegistrationState>(): {
  state: T
  dispatch: UserRegistrationDispatch
} {
  return {
    state: useUserRegistrationState<T>(),
    dispatch: useUserRegistrationDispatch()
  }
}

export function useUserRegistrationState<T extends UserRegistrationState>(): T {
  const state = React.useContext(UserRegistrationStateContext)
  if (state === undefined) {
    throw new Error(
      'useUserRegistrationState must be used within a UserRegistrationContextProvider'
    )
  }
  return state as T
}

export function useUserRegistrationDispatch(): UserRegistrationDispatch {
  const dispatch = React.useContext(UserRegistrationDispatchContext)
  if (dispatch === undefined) {
    throw new Error(
      'useUserRegistrationDispatch must be used within a UserRegistrationContextProvider'
    )
  }
  return dispatch
}
