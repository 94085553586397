import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { NewEmail } from '../state'
import { useTranslation } from '@local/translations'
import { PageInfoList } from '@toasttab/buffet-pui-wizard-templates'
import { ErrorModal } from '@toasttab/buffet-pui-modal'
import {
  UserMigrationChooseAnotherUsername,
  UserMigrationEmailExistsInCustomer
} from '@local/api/generated/gql/graphql'

const modalMessage = (
  error?:
    | UserMigrationChooseAnotherUsername
    | UserMigrationEmailExistsInCustomer
) => {
  if (error?.__typename === 'UserMigrationEmailExistsInCustomer') {
    return 'emailInUseInCustomer'
  } else {
    return 'emailInUse'
  }
}

const EmailErrorModal = (props: {
  error?:
    | UserMigrationChooseAnotherUsername
    | UserMigrationEmailExistsInCustomer
}) => {
  const { error } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(!!error)

  return (
    <ErrorModal
      isOpen={isOpen}
      heading={t('chooseAnotherEmail')}
      onOkay={() => setIsOpen(false)}
    >
      <p className='mb-2'>{modalMessage(error)}</p>
    </ErrorModal>
  )
}

export const NewAccount = () => {
  const { email, canChangeEmail, emailError } =
    useUserMigrationState<NewEmail>()
  const { t } = useTranslation()

  return (
    <>
      <Account
        canChangeEmail={canChangeEmail}
        source='NEW'
        email={email}
        emailHeading={t('newEmail')}
        heading={t('newAccount.heading')}
        body={t('newAccount.body')}
        questions={canChangeEmail && <Questions />}
      />
      <EmailErrorModal error={emailError} />
    </>
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <PageInfoList
      items={[
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('newAccount.question1'),
          description: t('newAccount.answer1')
        },
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('newAccount.question2'),
          description: t('newAccount.answer2')
        }
      ]}
    />
  )
}
