import * as React from 'react'
import { BulletIcon, CloseIcon, DoneIcon } from '@toasttab/buffet-pui-icons'
import { useUniqueId } from '@toasttab/buffet-utils'
import { Rule, RulesMap, RuleState } from './utils'
import { t, loadStrings } from '../defaultStrings'

export interface FieldRequirementsProps {
  /**
   * Rules to display. The name will be displayed, with an icon based on the
   * state.
   */
  rules: RulesMap<Rule>
  /** Optional id if this needs to be used to describe or label a field. */
  id?: string
  /** Optional testId to be used in tests to point to the whole component. */
  testId?: string
  /** Optional label for the requirements. Defaults to `Field requirements`. */
  label?: string
}

const generateIconRuleMap = () => ({
  [RuleState.NOT_RUN]: (
    <BulletIcon
      size='xs'
      className='text-gray-50'
      aria-label={t('there-is-no-input')}
    />
  ),
  [RuleState.INVALID]: (
    <CloseIcon
      size='xs'
      className='text-error'
      aria-label={t('this-rule-is-invalid')}
    />
  ),
  [RuleState.VALID]: (
    <DoneIcon
      size='xs'
      className='text-success'
      aria-label={t('this-rule-is-valid')}
    />
  )
})

/**
 * Displays rules and their validation state.
 */
export const FieldRequirements = ({
  rules,
  testId,
  id = 'requirements',
  label
}: FieldRequirementsProps) => {
  testId = useUniqueId(testId, 'field-requirements-')
  loadStrings()
  const IconRuleMap = generateIconRuleMap()
  if (!label) {
    label = t('field-requirements')
  }
  return (
    <div
      className='leading-none type-caption text-secondary'
      data-testid={testId}
      id={id}
    >
      <p className='mb-2 font-semibold'>{label}</p>
      <ul
        data-testid={`${testId}-list`}
        className='flex flex-col flex-wrap justify-start space-y-1'
      >
        {Object.entries(rules).map(([key, { name, state }]) => (
          <li
            key={key}
            data-testid={`${testId}-${key}-${state.toLowerCase()}-item`}
            className='flex flex-row items-center flex-grow flex-shrink-0 w-full space-x-1'
          >
            {IconRuleMap[state]}
            <p className='font-normal'>{name}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
