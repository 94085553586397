import { useEffect } from 'react'
import { useParamsFromRoute } from '../routes'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import * as React from 'react'
import { RegisterUser } from './RegisterUser'
import { RegistrationError } from './RegistrationError'
import { useUserRegistration } from '../UserRegistrationContextProvider'
import { UserRegistrationStatus } from '@local/api/documents/userRegistrationGraphQLDocuments'
import { CreateAndRegisterUser } from './CreateAndRegisterUser'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { RegistrationComplete } from './RegistrationComplete'
import { RegistrationErrorType } from './RegistrationErrorType'

export const GetRegistrationStatus = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserRegistration()
  const { companyCode, registrationId } = useParamsFromRoute('register')
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (!companyCode || !registrationId) {
      return
    }
    const fetchData = async () => {
      setLoading(true)

      if (companyCode) {
        const result = await client.query({
          query: UserRegistrationStatus,
          variables: {
            companyCode: companyCode,
            registrationId: registrationId
          }
        })

        const queryResult = result.data?.payrollUser?.userRegistrationStatus

        switch (queryResult?.__typename) {
          case 'UserRegistrationStatus':
            if (queryResult?.usernameExists) {
              dispatch({
                type: 'not-registered-toast-user-exists',
                email: queryResult.email,
                registrationId: registrationId
              })
            } else {
              dispatch({
                type: 'not-registered-no-toast-user',
                email: queryResult.email,
                registrationId: registrationId
              })
            }
            break
          case 'UserRegistrationUserAlreadyRegistered':
            dispatch({
              type: 'registered',
              email: queryResult.email,
              registrationId: registrationId
            })
            break
          case 'UserRegistrationExpired':
            dispatch({
              type: 'expired-link-registration-error',
              registrationId: registrationId
            })
            break
          case 'UserRegistrationIdNotFound':
            dispatch({
              type: 'no-id-registration-error',
              registrationId: registrationId
            })
            break
          case 'UserRegistrationCheckFailed':
          case 'UserMigrationFailed':
          default:
            dispatch({
              type: 'registration-error',
              registrationId: registrationId
            })
            showErrorSnackBar(t('anErrorOccurred'))
            break
        }
        setLoading(false)
      }
    }
    fetchData()
  }, [companyCode, registrationId, client, dispatch, showErrorSnackBar, t])

  if (state.type === 'initial-state' || loading) {
    return <MerryGoRound size='xxs' />
  } else if (state.type === 'not-registered-toast-user-exists') {
    return <RegisterUser />
  } else if (state.type === 'not-registered-no-toast-user') {
    return <CreateAndRegisterUser />
  } else if (state.type === 'registration-error') {
    return (
      <RegistrationError
        registrationErrorType={RegistrationErrorType.Generic}
      />
    )
  } else if (state.type === 'account-exists-registration-error') {
    return (
      <RegistrationError
        registrationErrorType={RegistrationErrorType.AccountAlreadyExists}
      />
    )
  } else if (state.type === 'expired-link-registration-error') {
    return (
      <RegistrationError
        registrationErrorType={RegistrationErrorType.ExpiredLink}
      />
    )
  } else if (state.type === 'no-id-registration-error') {
    return (
      <RegistrationError registrationErrorType={RegistrationErrorType.NoId} />
    )
  } else if (state.type === 'registered') {
    return <RegistrationComplete />
  }
}
