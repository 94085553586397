import { RestaurantIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { Header } from '@toasttab/buffet-pui-wizard-templates'
import { logout } from '../redirects'
import { navigateToDashboardPageOrLogout } from '../redirects'
import { useParamsFromRoute } from '../routes'

export const MigrationHeader = (props: {
  register: boolean
  companyCode: string
}) => {
  const { register, companyCode } = props
  const { companyCode: registerCompanyCode } = useParamsFromRoute('register')

  return register && registerCompanyCode ? (
    <Header
      logoRelativeLink={{
        link: `/${registerCompanyCode}/dashboard`,
        label: 'Toast Dashboard'
      }}
      title={registerCompanyCode}
      icon={<RestaurantIcon accessibility='decorative' />}
      onRequestClose={() =>
        navigateToDashboardPageOrLogout(registerCompanyCode)
      }
    />
  ) : (
    <Header
      logoRelativeLink={{
        link: '/user/migration',
        label: 'Toast Account Setup'
      }}
      title={companyCode}
      icon={<RestaurantIcon accessibility='decorative' />}
      onRequestClose={() => logout()}
    />
  )
}
