import * as React from 'react'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'
import { PageAside, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'

export const RegistrationErrorView = () => {
  const { t } = useTranslation()

  return (
    <Layout register={true}>
      <PageMain>
        <div className='text-center'>
          <Error500DesktopIllustration />
          <h1>{t('uhohSomethingWentWrong')}</h1>
          <p className='mb-6'>{t('tryLinkAgain')}</p>
        </div>
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
