import * as React from 'react'
import { useCallback } from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'
import { Logo } from '../logo'
import {
  ContentFooter,
  ContinueButton,
  NavigationContainer,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { useUserRegistrationState } from '../UserRegistrationContextProvider'
import { useParamsFromRoute } from '../routes'
import { Registered } from '../userRegistrationState'
import { navigateToDashboardPageOrLogout } from '../redirects'

export const RegistrationComplete = () => {
  const state = useUserRegistrationState<Registered>()
  const { t, Trans } = useTranslation()
  const { companyCode } = useParamsFromRoute('register')

  const handleContinue = useCallback(() => {
    if (companyCode) {
      return navigateToDashboardPageOrLogout(companyCode)
    } else {
      return navigateToDashboardPageOrLogout()
    }
  }, [companyCode])

  return (
    <Layout register={true}>
      <PageMain>
        <div className='p-10 flex flex-col items-center'>
          <Logo />
          <h1 className='type-headline-2 text-center' data-testid='title'>
            {t('complete.youAreAllSetUpShort')}
          </h1>
          <p className='mt-8 text-center'>
            <span data-testid='instructions'>
              <Trans
                i18nKey='complete.isReadyToBeUsedToLoginShort'
                components={{ bold: <strong /> }}
                values={{ email: state.email }}
              />{' '}
            </span>
          </p>

          <CelebrationIllustration />
        </div>

        <ContentFooter>
          <NavigationContainer>
            <ContinueButton onClick={handleContinue} testId='continue'>
              {t('continue')}
            </ContinueButton>
          </NavigationContainer>
        </ContentFooter>
      </PageMain>
    </Layout>
  )
}
