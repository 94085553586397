import * as React from 'react'
import { useCallback } from 'react'
import { PasswordView } from './PasswordView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { useUser } from '@toasttab/ec-session'
import { useUserRegistration } from '../UserRegistrationContextProvider'
import { useParamsFromRoute } from '../routes'
import { Register } from '@local/api/documents/userRegistrationGraphQLDocuments'
import { NoToastUserExists } from '../userRegistrationState'

export const Password = () => {
  const client = useApolloClient()
  const { state, dispatch } = useUserRegistration<NoToastUserExists>()
  const { showErrorSnackBar } = useSnackBar()
  const { companyCode, registrationId } = useParamsFromRoute('register')
  const { t } = useTranslation()
  const user = useUser()

  const register = useCallback(
    async (password: string) => {
      if (!registrationId || !companyCode) {
        return
      }
      if (password && registrationId && companyCode) {
        const registerMutation = async () => {
          const result = await client.mutate({
            mutation: Register,
            variables: {
              registrationId: registrationId,
              companyCode: companyCode,
              password: password
            },
            fetchPolicy: 'network-only'
          })

          const register = result.data?.payrollUser?.register

          switch (register?.__typename) {
            case 'UserRegistered':
              dispatch({
                type: 'registered',
                email: state.email,
                registrationId: registrationId
              })
              break
            case 'UserRegistrationUserAlreadyRegistered':
              dispatch({
                type: 'account-exists-registration-error',
                email: state.email,
                registrationId: registrationId
              })
              break
            case 'UserRegistrationExpired':
              dispatch({
                type: 'expired-link-registration-error',
                registrationId: registrationId
              })
              break
            case 'UserRegistrationIdNotFound':
            case 'UserRegistrationPayrollUserNotFound':
              dispatch({
                type: 'no-id-registration-error',
                registrationId: registrationId
              })
              break
            case 'UserRegistrationPasswordNotProvided':
            case 'UserRegistrationUserNotActive':
            case 'UserRegistrationEmailNotVerified':
            case 'UserRegistrationMutationFailed':
            default:
              dispatch({
                type: 'registration-error',
                registrationId: registrationId
              })
              showErrorSnackBar(t('anErrorOccurred'))
              break
          }
        }
        registerMutation()
      }
    },
    [state.registrationId, user.email, client, dispatch, showErrorSnackBar]
  )

  return <PasswordView onContinue={register} />
}
