import * as React from 'react'
import cx from 'classnames'
import { SkipThisStepButton } from '../CommonActions'

export interface ContentHeaderProps {
  testId?: string | number
  className?: string
  title?: string
  description?: string
  skipAction?: () => void
  contentDivider?: boolean
  emptyPage?: boolean
  children?: React.ReactNode
}

export const ContentHeader = ({
  testId = 'content-header',
  className,
  title,
  description,
  skipAction,
  contentDivider = false,
  emptyPage = false,
  children
}: ContentHeaderProps) => {
  return (
    <div data-testid={testId} className={cx(className, 'pb-6 w-full')}>
      <ContentRow>
        <ContentColumn
          testId={`${testId}-page-info-content-column-1`}
          fullWidth={!skipAction}
        >
          {title && <PageTitle>{title}</PageTitle>}
          {description && <PageSubtitle>{description}</PageSubtitle>}
        </ContentColumn>
        {!!skipAction && (
          <ContentColumn
            testId={`${testId}-page-info-content-column-2`}
            alignBottom
          >
            <SkipThisStepButton className='mt-4' onClick={skipAction} />
          </ContentColumn>
        )}
      </ContentRow>
      {contentDivider && <PageBreak emptyPage={emptyPage} />}
      {children}
    </div>
  )
}

export const ContentRow = ({
  testId = 'page-info-content',
  children
}: {
  testId?: string | number
  children: React.ReactNode
}) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        'flex justify-between',
        'flex-col md:flex-row lg:flex-col xl:flex-row',
        'gap-x-4 lg:gap-x-8'
      )}
    >
      {children}
    </div>
  )
}

export const ContentColumn = ({
  testId = 'page-info-content-column',
  children,
  alignBottom = false
}: {
  testId?: string
  children: React.ReactNode
  alignBottom?: boolean
  fullWidth?: boolean
}) => {
  return (
    <div
      data-testid={testId}
      className={cx({ 'mt-auto ml-auto': alignBottom })}
    >
      {children}
    </div>
  )
}

export interface PageTitleProps {
  testId?: string | number
  className?: string
  children: React.ReactNode
}
export const PageTitle = ({
  testId = 'page-title',
  className,
  children
}: PageTitleProps) => {
  return (
    <h1
      data-testid={testId}
      className={cx(
        className,
        'type-headline-4 md:type-headline-2 font-medium text-default',
        'mt-0 mb-4 md:mb-6'
      )}
    >
      {children}
    </h1>
  )
}

export interface PageSubtitleProps {
  testId?: string | number
  className?: string
  children: React.ReactNode
}
export const PageSubtitle = ({
  testId = 'page-subtitle',
  className,
  children
}: PageSubtitleProps) => {
  return (
    <h2
      data-testid={testId}
      className={cx(className, 'type-default text-default')}
    >
      {children}
    </h2>
  )
}

export interface PageBreakProps {
  emptyPage?: boolean
  testId?: string
}
export const PageBreak = ({
  emptyPage = false,
  testId = 'page-break'
}: PageBreakProps) => {
  const hr = (
    <hr data-testid={testId} className='mt-6 h-0.5 bg-gray-125 border-none' />
  )

  return emptyPage ? (
    <div data-testid={`${testId}-parent`} className='pt-4 pb-6'>
      {hr}
    </div>
  ) : (
    hr
  )
}
