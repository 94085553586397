import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AddIcon, ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { useTranslation } from '@local/translations'
import { PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { Layout } from '../layout'

export const VerifyEmailView = (props: {
  email: string
  canChangeEmail: boolean
  onEditEmail: () => void
  onResendEmail: () => void
  resendDisabled: boolean
}) => {
  const { email, canChangeEmail, onEditEmail, onResendEmail, resendDisabled } =
    props
  const { t } = useTranslation()

  return (
    <Layout register={false}>
      <PageMain>
        <div className='type-headline-2 pt-10'>
          {t('email.verify.checkYourEmail')}
        </div>
        <div className='pt-6 flex'>
          <div className='flex-auto w-1/2'>
            {t('email.verify.weHaveSentAnEmailTo')}{' '}
            <span className='font-bold'>{email}</span>{' '}
            {t('email.verify.toVerifyYourEmailAndConnectYourAccounts')}
          </div>
          <div className='flex-auto text-right'>
            <Button
              testId='resend-email'
              onClick={onResendEmail}
              variant='link'
              disabled={resendDisabled}
              iconRight={<ArrowForwardIcon accessibility='decorative' />}
            >
              {t('resendEmail')}
            </Button>
          </div>
        </div>
        <hr className='mt-6 h-px border-1 bg-gray-125 mb-6' />
        {canChangeEmail && (
          <div className='flex justify-end'>
            <Button
              testId='edit-email'
              onClick={onEditEmail}
              type='button'
              variant='secondary'
              iconLeft={<AddIcon accessibility='decorative' />}
            >
              {t('editEmail')}
            </Button>
          </div>
        )}
      </PageMain>
    </Layout>
  )
}
