import { graphql } from '../generated/gql'

export const UserRegistrationStatus = graphql(/* GraphQL */ `
  query userRegistrationStatus($companyCode: String!, $registrationId: ID!) {
    payrollUser {
      userRegistrationStatus(
        companyCode: $companyCode
        registrationId: $registrationId
      ) {
        __typename
        ... on UserRegistrationStatus {
          usernameExists
          email
        }
        ... on UserRegistrationUserAlreadyRegistered {
          registrationId
          email
        }
        ... on UserRegistrationIdNotFound {
          registrationId
        }
        ... on UserRegistrationExpired {
          registrationId
        }
        ... on UserRegistrationCheckFailed {
          registrationId
          message
        }
        ... on UserMigrationFailed {
          message
        }
      }
    }
  }
`)

export const Register = graphql(/* GraphQL */ `
  mutation register(
    $registrationId: ID!
    $companyCode: String!
    $password: String
  ) {
    payrollUser {
      register(
        registrationId: $registrationId
        companyCode: $companyCode
        password: $password
      ) {
        __typename
        ... on UserRegistered {
          userId
          toastIdentityGuid
          registeredAt
        }
        ... on UserRegistrationUserAlreadyRegistered {
          registrationId
        }
        ... on UserRegistrationIdNotFound {
          registrationId
        }
        ... on UserRegistrationExpired {
          registrationId
        }
        ... on UserMigrationFailed {
          message
        }
        ... on UserRegistrationPayrollUserNotFound {
          registrationId
        }
        ... on UserRegistrationPasswordNotProvided {
          registrationId
        }
        ... on UserRegistrationMutationFailed {
          registrationId
        }
        ... on UserRegistrationEmailNotVerified {
          registrationId
          email
          message
        }
        ... on UserRegistrationUserNotActive {
          registrationId
        }
      }
    }
  }
`)
