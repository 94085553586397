import { UserMigrationState } from './state'
import * as React from 'react'
import { UserMigrationAction } from './actions'
import { UserRegistrationState } from './userRegistrationState'
import { UserRegistrationAction } from './actions'

export const userMigrationReducer = (
  state: UserMigrationState,
  action: UserMigrationAction
): UserMigrationState => {
  switch (action.type) {
    case 'set-return-url':
      return {
        ...state,
        returnUrl: action.returnUrl
      }
    case 'restart':
      return {
        type: 'initial-state',
        source: 'NEW',
        returnUrl: state.returnUrl
      }
    case 'migration-defaults':
      if (action.source === 'NEW' || !action.email) {
        return {
          type: 'new-email',
          source: 'NEW',
          returnUrl: state.returnUrl,
          canChangeEmail: true
        }
      } else if (action.source === 'PAYROLL') {
        return {
          type: 'payroll-email',
          source: 'PAYROLL',
          email: action.email,
          returnUrl: state.returnUrl,
          canChangeEmail: action.canChangeEmail
        }
      } else if (action.source === 'TOAST') {
        return {
          type: 'toast-email',
          source: 'TOAST',
          email: action.email,
          returnUrl: state.returnUrl,
          canChangeEmail: action.canChangeEmail
        }
      }
      break
    case 'select-email':
      return {
        type: 'email-selected',
        email: action.email,
        source: action.source,
        returnUrl: state.returnUrl
      }
    case 'account-verification-email-sent':
      if (state.type === 'payroll-email' || state.type === 'toast-email') {
        return {
          type: 'wait-for-email-verification',
          email: state.canChangeEmail ? action.email : state.email,
          canChangeEmail: state.canChangeEmail,
          source: action.source,
          idempotencyKey: action.idempotencyKey,
          returnUrl: state.returnUrl
        }
      } else {
        return {
          type: 'wait-for-email-verification',
          email: action.email,
          canChangeEmail: true,
          source: action.source,
          idempotencyKey: action.idempotencyKey,
          returnUrl: state.returnUrl
        }
      }
    case 'email-verified':
      return {
        type: 'email-verified',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'email-confirmed':
      return {
        type: 'email-confirmed',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'email-confirmation-error':
      return {
        type: 'email-confirmation-error',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'wait-for-password':
      return {
        type: 'wait-for-password',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'migrated':
      return {
        type: 'migrated',
        email: action.email,
        source: state.source,
        toastIdentityGuid: action.toastIdentityGuid,
        userId: action.userId,
        companyCodes: action.companyCodes,
        returnUrl: state.returnUrl
      }
    case 'choose-another-email':
      return {
        type: 'new-email',
        email: action.email,
        source: 'NEW',
        returnUrl: state.returnUrl,
        emailError: action.emailError,
        canChangeEmail: true
      }
    default:
      throw new Error(
        `Invalid action ${JSON.stringify(action)} for state ${state.type}`
      )
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export const userRegistrationReducer = (
  state: UserRegistrationState,
  action: UserRegistrationAction
): UserRegistrationState => {
  switch (action.type) {
    case 'registration-error':
      return {
        type: 'registration-error',
        source: 'PAYROLL',
        registrationId: action.registrationId
      }
    case 'account-exists-registration-error':
      return {
        type: 'account-exists-registration-error',
        source: 'PAYROLL',
        email: action.email,
        registrationId: action.registrationId
      }
    case 'expired-link-registration-error':
      return {
        type: 'expired-link-registration-error',
        source: 'PAYROLL',
        registrationId: action.registrationId
      }
    case 'no-id-registration-error':
      return {
        type: 'no-id-registration-error',
        source: 'PAYROLL',
        registrationId: action.registrationId
      }
    case 'registered':
      return {
        type: 'registered',
        source: 'PAYROLL',
        email: action.email,
        registrationId: action.registrationId
      }
    case 'not-registered-no-toast-user':
      return {
        type: 'not-registered-no-toast-user',
        email: action.email,
        registrationId: action.registrationId,
        source: 'PAYROLL'
      }
    case 'not-registered-toast-user-exists':
      return {
        type: 'not-registered-toast-user-exists',
        registrationId: action.registrationId,
        email: action.email,
        source: 'PAYROLL'
      }
    default:
      throw new Error(
        `Invalid action ${JSON.stringify(action)} for state ${state.type}`
      )
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export type UserRegistrationDispatch = React.Dispatch<UserRegistrationAction>

export type UserMigrationDispatch = React.Dispatch<UserMigrationAction>
