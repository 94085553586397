import React from 'react'
import { useRoutes } from 'react-router-dom'
import { pathTemplate } from './routes'
import { ConfirmEmail } from '../email'
import { UserMigrationApp } from '../UserMigrationApp'
import { UserRegistrationApp } from '../UserRegistrationApp'

export function Routes() {
  return useRoutes([
    {
      index: true,
      path: '*',
      element: <UserMigrationApp />
    },
    {
      path: pathTemplate('confirmEmail'),
      element: <ConfirmEmail />
    },
    {
      path: pathTemplate('register'),
      element: <UserRegistrationApp />
    }
  ])
}
