import type singleSpaCss from 'single-spa-css'

type CSSLifecycles<ExtraProps> = ReturnType<typeof singleSpaCss<ExtraProps>>
export function mergeCSSLifecycles<ExtraProps>(
  ...args: CSSLifecycles<ExtraProps>[]
): CSSLifecycles<ExtraProps> {
  return {
    bootstrap: (config) =>
      Promise.all(args.map((lifecycle) => lifecycle.bootstrap(config))),
    mount: (config) =>
      Promise.all(args.map((lifecycle) => lifecycle.mount(config))),
    unmount: (config) =>
      Promise.all(args.map((lifecycle) => lifecycle.unmount(config)))
  }
}
