import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const CheckCircleSelectedFillIcon = React.forwardRef(
  function CheckCircleSelectedFillIcon(
    props: IconProps,
    ref?: React.ForwardedRef<HTMLElement>
  ) {
    return (
      <IconWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              className={cx(
                'inline-block align-middle',
                sizes[props.size || 'sm'].className
              )}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25s9.75 4.365 9.75 9.75zm-5.27-3.039a.746.746 0 10-1.2-.887l-4.541 6.144-2.273-2.43a.746.746 0 00-1.09 1.02l2.885 3.084a.746.746 0 001.145-.066l5.074-6.865z'
                fill='currentColor'
              />
            </svg>
          }
        </>
      </IconWrapper>
    )
  }
)
