import * as React from 'react'
import { VisibilityOffIcon, VisibilityOnIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { t, loadStrings } from '../defaultStrings'

export const usePasswordVisibility = () => {
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false)
  loadStrings()
  const PasswordVisibilityIcon = isPasswordVisible
    ? VisibilityOffIcon
    : VisibilityOnIcon

  const Suffix = () => (
    <IconButton
      icon={
        <PasswordVisibilityIcon
          aria-label={
            isPasswordVisible ? t('hide-password') : t('show-password')
          }
        />
      }
      onClick={() => setPasswordVisibility((oldVis) => !oldVis)}
      contained
      iconColor='secondary'
    />
  )

  return {
    type: isPasswordVisible ? 'text' : 'password',
    Suffix
  }
}
