export function logout(returnUrl?: string) {
  if (returnUrl) {
    const encodedReturnUrl = encodeURIComponent(returnUrl)
    window.location.assign(`/Logout.aspx?returnUrl=${encodedReturnUrl}`)
  } else {
    window.location.assign('/Logout.aspx')
  }
}

export function navigateToDashboardPageOrLogout(companyCode?: string) {
  if (companyCode) {
    window.location.assign(`/${companyCode}/dashboard`)
  } else {
    window.location.assign('/Logout.aspx')
  }
}
