import { string } from 'yup'
import { t } from '../defaultStrings'

export const deprecatedSchema = string()
  .required('required')
  .min(7, 'min')
  .matches(/[0-9]+/g, 'number')
  .matches(/[a-zA-Z]+/g, 'letter')
  // `matches` checks that the given regexes passes for the given string.
  // However, since we want to look that no space is present, and [^\s] will
  // match any non space, it will make it pass. Thus we need a custom test.
  .test('space', 'space', (value) => !!value && !/[\s]+/g.test(value))

export const updatedSchema = string()
  .required('required')
  .min(10, 'min')
  .matches(/[0-9]+/g, 'number')
  .matches(/[a-z]+/g, 'lowercase')
  .matches(/[A-Z]+/g, 'uppercase')
  .matches(/(!|@|#|\$|%|\^|&|\*)+/g, 'special')

export const deprecatedEnglishRules = {
  min: { name: 'have at least 7 characters' },
  number: { name: 'have at least 1 number' },
  letter: { name: 'have at least 1 letter' },
  space: { name: 'contain no spaces' }
}

export const updatedEnglishRules = {
  min: { name: 'have at least 10 characters' },
  number: { name: 'have at least 1 number' },
  lowercase: { name: 'have at least 1 lowercase letter' },
  uppercase: { name: 'have at least 1 uppercase letter' },
  special: {
    name: 'have at least 1 of the following special characters: !@#$%^&*'
  }
}

export const deprecatedRules = () => {
  return {
    min: { name: t('have-at-least-7-characters') },
    number: { name: t('have-at-least-1-number') },
    letter: { name: t('have-at-least-1-letter') },
    space: { name: t('contain-no-spaces') }
  }
}

export const updatedRules = () => {
  return {
    min: { name: t('have-at-least-10-characters') },
    number: { name: t('have-at-least-1-number') },
    lowercase: { name: t('have-at-least-1-lowercase') },
    uppercase: { name: t('have-at-least-1-uppercase') },
    special: {
      name: t('have-at-least-1-of')
    }
  }
}
