import * as React from 'react'
import {
  IllustrationWrapper,
  IllustrationProps
} from '../../IllustrationWrapper'
export const CelebrationIllustration = React.forwardRef(
  function CelebrationIllustration(
    props: IllustrationProps,
    ref?: React.ForwardedRef<HTMLDivElement>
  ) {
    const resizeToContainer = props.resizeToContainer
    return (
      <IllustrationWrapper ref={ref} {...props}>
        <>
          {
            <svg
              width={resizeToContainer ? '100%' : '260'}
              height={resizeToContainer ? '100%' : '225'}
              viewBox='0 0 260 225'
              fill='none'
              className='inline-block'
            >
              <g clipPath='url(#celebration_svg__clip0_2255_624)'>
                <path
                  d='M255.114 146.908a4.884 4.884 0 00.954-9.677 4.88 4.88 0 00-5.016 2.077 4.887 4.887 0 004.062 7.6z'
                  fill='silver'
                />
                <path
                  d='M234.349 16.214a4.883 4.883 0 004.514-3.015 4.89 4.89 0 00-1.058-5.325 4.889 4.889 0 00-7.518.74 4.885 4.885 0 004.062 7.6z'
                  fill='#E5E5E5'
                />
                <path
                  d='M65.791 13.77a4.886 4.886 0 100-9.77 4.886 4.886 0 000 9.77z'
                  fill='silver'
                />
                <path
                  d='M145.438 39s-6.237 11.306 5.057 17.534c0 0-11.294-6.228-17.529 5.078 0 0 6.235-11.306-5.057-17.536 0 0 11.292 6.23 17.529-5.077zM217.404 84.004s-4.274 19.412 15.116 23.682c0 0-19.39-4.27-23.665 15.142 0 0 4.275-19.412-15.114-23.682 0 0 19.389 4.27 23.663-15.142zM149.238 87.398s1.565 10.173 11.724 8.61c0 0-10.159 1.562-8.596 11.735 0 0-1.563-10.172-11.724-8.61 0 0 10.161-1.562 8.596-11.735z'
                  stroke='#FF4C00'
                  strokeWidth={4}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M109.305 19.747a8.86 8.86 0 008.689-10.587 8.861 8.861 0 00-16.055-3.193 8.863 8.863 0 001.102 11.186 8.86 8.86 0 006.264 2.594zM125.909 124.952a7.875 7.875 0 10-.002-15.75 7.875 7.875 0 00.002 15.75v0z'
                  stroke='silver'
                  strokeWidth={4}
                />
                <path
                  d='M116.195 94.829a11.813 11.813 0 008.353-20.164 11.812 11.812 0 10-8.353 20.164v0z'
                  stroke='#E5E5E5'
                  strokeWidth={4}
                />
                <path
                  d='M178.961 83.693c7.965-1.573 15.795-7.05 17.355-15.018a23.63 23.63 0 00-.125-8.243c-.453-2.836-1.191-5.748-3.043-7.943-1.851-2.194-5.088-3.448-7.694-2.241a4.264 4.264 0 00-1.988 1.81 4.729 4.729 0 00.556 4.804 10.785 10.785 0 003.958 3.123 19.164 19.164 0 0010.088 2.504 10.814 10.814 0 008.72-5.097c1.497-2.685 1.468-5.936 1.262-9.003-.373-5.538-1.188-11.168.029-16.582 1.216-5.415 5.076-10.703 10.554-11.591'
                  stroke='silver'
                  strokeWidth={4}
                  strokeLinecap='round'
                />
                <path
                  d='M76.105 108.448A60.224 60.224 0 0186 88.702c3.64-4.835 8.357-10.29 7.044-16.838-1.354-6.755-8.799-8.085-14.596-8.165-2.682-.037-7.054.596-8.28 3.392-1.605 3.663 2.914 6.73 6.04 6.94a11 11 0 009.471-4.5c3.239-4.874 3.642-12.124 1.693-17.553-2.636-7.343-6.352-13.253-14.822-13.156-4.17.048-12.041 3.214-10.64 8.62 1.995 7.7 14.106 6.03 19.481 4.217a27.393 27.393 0 0017.061-16.35'
                  stroke='#E5E5E5'
                  strokeWidth={4}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M117.807 153.256a11.697 11.697 0 013.053-4.593 44.154 44.154 0 0127.093-13.148c12.845-1.293 27.11 5.019 28.46 19.318.696 7.378-4.591 13.472-12.377 10.658-7.304-2.64-8.102-13.757-7.316-20.276 1.824-15.126 16.596-21.173 29.906-15.339 6.676 2.926 11.149 8.156 16.34 13.012a28.558 28.558 0 0019.054 7.579c5.286.113 13.31.054 15.907-5.619'
                  stroke='#E5E5E5'
                  strokeWidth={4}
                  strokeLinecap='round'
                />
                <path
                  d='M35.209 122.828l13.98 20.558a118.474 118.474 0 0034.165 33.208l18.962 12.12M25.42 157.689l8.203 10.984a110.319 110.319 0 0025.119 24.364l8.605 6.026M15.305 186.167l5.607 7.296a77.887 77.887 0 0016.853 16.184'
                  stroke='#E5E5E5'
                  strokeWidth={4}
                />
                <path
                  d='M137.798 176.009c5.917-5.917-9.22-30.648-33.809-55.238-24.59-24.59-49.32-39.726-55.238-33.809-5.917 5.917 9.22 30.648 33.809 55.238 24.59 24.589 49.321 39.726 55.238 33.809z'
                  stroke='silver'
                  strokeWidth={4}
                />
                <path
                  d='M137.032 176.775l-66.908 22.384-66.908 22.384 22.385-66.907 22.384-66.908'
                  stroke='silver'
                  strokeWidth={4}
                />
              </g>
              <defs>
                <clipPath id='celebration_svg__clip0_2255_624'>
                  <rect width={260} height={225} fill='#fff' />
                </clipPath>
              </defs>
            </svg>
          }
        </>
      </IllustrationWrapper>
    )
  }
)
