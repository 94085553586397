/* For a module at http://localhost:8080/dist/js/main.js,
 * this will set the webpack public path to be
 * http://localhost:8080/dist/js/
 */
import 'systemjs-webpack-interop/auto-public-path'

import singleSpaCss from 'single-spa-css'

type SingleSpaCssOpts = Parameters<typeof singleSpaCss>[0]

const baseConfig: SingleSpaCssOpts = {
  cssUrls: [],
  webpackExtractedCss: true
}

export const singleSpaCssLifecycles = singleSpaCss(baseConfig)
export function createSingleSpaCssLifecycles(
  config: Partial<SingleSpaCssOpts> = {}
) {
  return singleSpaCss({ ...baseConfig, ...config })
}

export { mergeCSSLifecycles } from './utils'
