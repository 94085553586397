import * as React from 'react'
import { useEffect } from 'react'
import { useUser } from '@toasttab/ec-session'
import { useTranslation } from '@local/translations'
import { RegistrationError } from './register/RegistrationError'
import { RegistrationErrorType } from './register/RegistrationErrorType'
import { CreateAndRegisterUser } from './register/CreateAndRegisterUser'
import { RegisterUser } from './register/RegisterUser'
import { GetRegistrationStatus } from './register/GetRegistrationStatus'
import { RegistrationComplete } from './register/RegistrationComplete'
import { useUserRegistration } from './UserRegistrationContextProvider'

export const UserRegistrationApp = () => {
  const { state } = useUserRegistration()
  const user = useUser()
  const { changeLanguage } = useTranslation()

  useEffect(() => {
    changeLanguage(user?.language === 'es' ? 'es-US' : 'en-US')
  }, [user.language, changeLanguage])

  switch (state.type) {
    case 'initial-state':
      return <GetRegistrationStatus />
    case 'not-registered-no-toast-user':
      return <CreateAndRegisterUser /> //display password form, register user, and then show complete page
    case 'not-registered-toast-user-exists':
      return <RegisterUser /> //register user and then show complete page
    case 'registration-error':
      return (
        <RegistrationError
          registrationErrorType={RegistrationErrorType.Generic}
        />
      )
    case 'account-exists-registration-error':
      return (
        <RegistrationError
          registrationErrorType={RegistrationErrorType.AccountAlreadyExists}
        />
      )
    case 'expired-link-registration-error':
      return (
        <RegistrationError
          registrationErrorType={RegistrationErrorType.ExpiredLink}
        />
      )
    case 'no-id-registration-error':
      return (
        <RegistrationError registrationErrorType={RegistrationErrorType.NoId} />
      )
    case 'registered':
      return <RegistrationComplete />
    default:
      return <></>
  }
}
