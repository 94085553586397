import * as React from 'react'
import { CheckCircleSelectedFillIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageAside,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { Layout } from '../layout'
import { logout } from '../redirects'

const NumberedListItem = (props: {
  number: number
  children: React.ReactNode
}) => {
  const { number, children } = props
  return (
    <div>
      <span className='h-4 w-4 rounded-full text-white bg-primary text-center p-3 inline-grid place-content-center mr-2 mb-4'>
        {number}
      </span>
      {children}
    </div>
  )
}

const CheckmarkListItem = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <div className='mb-4'>
      <CheckCircleSelectedFillIcon
        accessibility='decorative'
        className='text-success mr-1 '
      />
      {children}
    </div>
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <div className='space-y-6'>
      <div className='font-semibold mb-4'>{t('start.whatYouWillNeedToDo')}</div>
      <div>
        <NumberedListItem number={1}>{t('start.question1')}</NumberedListItem>
        <NumberedListItem number={2}>{t('start.question2')}</NumberedListItem>
        <NumberedListItem number={3}>{t('start.question3')}</NumberedListItem>
      </div>
    </div>
  )
}

const DeferButton = (props: {
  canDefer: boolean
  loading: boolean
  onDeferMigration: () => void
}) => {
  const { t } = useTranslation()
  const { canDefer, loading, onDeferMigration } = props

  if (canDefer) {
    return (
      <Button
        testId='defer-migration'
        variant='secondary'
        onClick={onDeferMigration}
        disabled={loading}
      >
        {t('skipForNow')}
      </Button>
    )
  } else {
    return (
      <Button
        testId='logout'
        variant='secondary'
        onClick={() => logout()}
        disabled={loading}
      >
        {t('logout')}
      </Button>
    )
  }
}

const NavigationButtons = (props: {
  loading: boolean
  onDeferMigration: () => void
  onContinue: () => void
  canDefer: boolean
}) => {
  const { loading, onDeferMigration, onContinue, canDefer } = props
  const { t } = useTranslation()

  return (
    <NavigationContainer>
      <DeferButton
        canDefer={canDefer}
        loading={loading}
        onDeferMigration={onDeferMigration}
      />
      <ContinueButton onClick={onContinue} disabled={loading} testId='continue'>
        {t('continue')}
      </ContinueButton>
    </NavigationContainer>
  )
}

export const StartView = (props: {
  loading: boolean
  onDeferMigration: () => void
  onContinue: () => void
  canDefer: boolean
}) => {
  const { t } = useTranslation()
  const { onDeferMigration, onContinue, loading, canDefer } = props

  return (
    <Layout register={false}>
      <PageMain>
        <ContentHeader
          title={t('start.heading')}
          description={t('start.body')}
          contentDivider={true}
        />
        <div className='font-semibold mb-4'>{t('start.listHeading')}</div>
        <div>
          <CheckmarkListItem>{t('start.listItem1')}</CheckmarkListItem>
          <CheckmarkListItem>{t('start.listItem2')}</CheckmarkListItem>
          <CheckmarkListItem>{t('start.listItem3')}</CheckmarkListItem>
        </div>
        <ContentFooter>
          <NavigationButtons
            loading={loading}
            onDeferMigration={onDeferMigration}
            onContinue={onContinue}
            canDefer={canDefer}
          />
        </ContentFooter>
      </PageMain>
      <PageAside>
        <Questions />
      </PageAside>
    </Layout>
  )
}
