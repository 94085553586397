import { BaseSchema, ValidationError } from 'yup'

/**
 * The different state for a rule.
 */
export enum RuleState {
  /** Before the validation ran the first time. */
  NOT_RUN = 'not-run',
  /** When the validation rule failed with the input. */
  INVALID = 'invalid',
  /** WHen the validation rule passed with the input. */
  VALID = 'valid'
}

/**
 * A rule without the state, used to give the base rules before running the
 * validation.
 */
export interface BaseRule {
  name: string
}

/**
 * A rule with a state defined by the validation state.
 */
export interface Rule extends BaseRule {
  state: RuleState
}

/**
 * Map of items that extend `BaseRule` (e.g. `Rule`), indexed by a string, which
 * will be used during `yup` validation, and for `testId`s.
 */
export interface RulesMap<TValue extends BaseRule> {
  [key: string]: TValue
}

/**
 * Transform the output of the `validate` method into rules with a state based
 * on the validation errors.
 * @param rules
 * A list of rules to mapped the errors to.
 * @param hasRun
 * Determine whether the validation has run or not, based on user input.
 * @param errors
 * The errors that occur during the validation. Absent if no error occured and
 * the input is valid according to the `yup` schema.
 * @returns
 * An array of rules with state based on the errors.
 */
export const transformSchemaValidation = (
  rules: RulesMap<BaseRule>,
  hasRun: boolean,
  errors?: string[]
): RulesMap<Rule> => {
  let requiredFails = false
  if (!rules?.['required'] && !!errors && errors.includes('required')) {
    requiredFails = true
  }
  return Object.entries(rules).reduce(
    (acc, [key, rule]) => ({
      ...acc,
      [key]: {
        ...rule,
        state: hasRun
          ? requiredFails || (!!errors && errors.includes(key))
            ? RuleState.INVALID
            : RuleState.VALID
          : RuleState.NOT_RUN
      }
    }),
    {}
  )
}

export const SEPARATOR = '<ERR>'

/**
 * Internal method to be provided to the `validate` prop to `formik` to validate
 * the field. We can't use the `formik` way with `yup` to validate the field for
 * 2 reasons:
 * 1. Providing the schema only works at the form level, not the field;
 * 2. `formik` will only give the first error during validation, not all of them.
 * The error will be a join of all the errors found during validation, with an
 * internal separator. It's not meant to be returned to the user.
 * @param schema
 * The `yup` schema for this field.
 * @returns
 * A function that return a Promise used by `formik` to determine whether the
 * field has errors or not. The Promise will return a string if errors occured,
 * nothing otherwise.
 */
export const validate =
  <TCast, TContext, TOutput>(
    schema: BaseSchema<TCast, TContext, TOutput>,
    context?: TContext
  ): ((value: TOutput) => Promise<string | void>) =>
  (value) =>
    schema
      .validate(value, { abortEarly: false, context })
      .then(() => {})
      .catch((e: ValidationError) => e.errors.join(SEPARATOR))

/**
 * Internal method used to transform the hijacked error string returned by
 * our `validate` function, to an array of errors.
 * @param error
 * String given by the `formik` meta object for the field, hijacked to return
 * multiple errors.
 * @returns
 * The different errors in an array.
 */
export const transformFormikErrors = (error?: string): string[] =>
  !!error ? error.split(SEPARATOR) : []
